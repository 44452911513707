<template>
  <div class="separator-container">
    <love-marketing-separator class="svg-icon mb-1" v-if="isILoveMarketingEvent"></love-marketing-separator>
    <div class="pism-separator" v-else-if="isPismEvent">
      <pism-separator-decorator></pism-separator-decorator>
      <pism-separator></pism-separator>
      <pism-separator-decorator></pism-separator-decorator>
    </div>
    <google-summit-separator v-else-if="isGoogleSummit"></google-summit-separator>
    <img class="custom-separator" v-else-if="customSeparator" :src="$t(`custom.${customSeparator}`)" alt="separator">
    <default-separator v-else></default-separator>
  </div>
</template>

<script>
import DefaultSeparator from "@/assets/separator.svg";
import LoveMarketingSeparator from "@/assets/custom/separator_ilovemarketing.svg";
import PismSeparator from "@/assets/custom/pism_separator.svg";
import PismSeparatorDecorator from "@/assets/custom/pism_separator_decorator.svg";
import GoogleSummitSeparator from "@/assets/custom/google/google_separator.svg";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Separator",

  components: {
    DefaultSeparator,
    LoveMarketingSeparator,
    PismSeparator,
    PismSeparatorDecorator,
    GoogleSummitSeparator,
  },

  computed: {
    ...mapState(["eventId"]),
    ...mapGetters(["customSeparator"]),

    isILoveMarketingEvent() {
      return this.eventId === 1000;
    },

    isPismEvent() {
      return this.eventId === 1312;
    },

    isGoogleSummit() {
      return this.eventId === 1387;
    }
  },
};
</script>

<style scoped lang="scss">
.separator-container {
  //min-height: 30px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 18px;
  max-height: 30px;
}

.custom-separator {
  //width: 100%;
  //height: 100%;
}

.pism-separator {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 7px;
}
</style>
